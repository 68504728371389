/*--------------------------------------------------------------
PRODUCTEURS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Bouquet
- Penta
- Init
- Destroy
--------------------------------------------------------------*/

/*--------------------------------------------------------------
	Bouquet
--------------------------------------------------------------*/
var bouquet = {
	init: function () {
		this.divs = $$.all('.bouquet div');
		if (isTouch) return;
		this.resize();
		window.addEventListener('resize', this.resize);
		window.addEventListener('mousemove', this.mousemove);
	},
	destroy: function () {
		if (isTouch) return;
		window.removeEventListener('resize', this.resize);
		window.removeEventListener('mousemove', this.mousemove);
	},
	resize: function () {
		bouquet.center = {
			x: window.innerWidth / 2,
			y: window.innerHeight / 2,
		};
	},
	mousemove: function (e) {
		bouquet.x = (e.clientX - bouquet.center.x) / 30;
		bouquet.y = (e.clientY - bouquet.center.y) / 30;
		var i = bouquet.divs.length;
		while (i--) {
			var sign = parseFloat(bouquet.divs[i].getAttribute('data-mouse'));
			if (bouquet.divs[i].getAttribute('data-axis') == 'x')
				bouquet.divs[i].style.transform =
					'translateX(' + bouquet.x * sign + 'px)';
			else
				bouquet.divs[i].style.transform =
					'translate(' +
					bouquet.x * sign +
					'px, ' +
					bouquet.y * 1.5 * sign +
					'px)';
		}
	},
};

/*--------------------------------------------------------------
	Penta
--------------------------------------------------------------*/
var penta = {
	select: function (e, ul) {
		penta.clean(ul);
		penta.active(e);
	},
	clean: function (ul) {
		var lis = ul.children;
		var i = lis.length;
		while (i--) {
			lis[i].className = '';
		}
	},
	active: function (e) {
		var li = e.target;
		var i = 4;
		while (i--) {
			li = li.nodeName == 'LI' ? li : li.parentNode;
		}
		li.className = 'on';
		var lis = li.parentNode.children;
		var ind = Array.prototype.indexOf.call(lis, li);
		$$.first('.i span').textContent = li.get('em').textContent;
	},
};

/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.producteurs = function () {
	bouquet.init();
	parallaxes.init();
};

/*--------------------------------------------------------------
	Destroy
--------------------------------------------------------------*/
destroy.producteurs = function () {
	parallaxes.destroy();
	bouquet.destroy();
};
