/*--------------------------------------------------------------
HOME  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Espace prive
- Contact
- Actus
--------------------------------------------------------------*/

/*--------------------------------------------------------------
	Espace prive
--------------------------------------------------------------*/
class espace_prive {
	constructor() {
		this._logged = false;
		this._opened = false;
		this._closingTimeout = null;
		this._el = $$.id('popupEP');
		this._emailInput = this._el.get('input[name=login]');
		this._passwordInput = this._el.get('input[name=password]');
		this._init();
		this._errorDiv = this._el.get('.error');
		this._loginQuery = `mutation ($email: String!, $password: String!) {
			login (
				email: $email
				password: $password
				asAdmin: false
			) {
				token
			}
		}`;
		this._links = {
			'm.charraire.localhost': {
				espace_prive: 'http://espace-prive.charraire.localhost',
				local: 'http://local.charraire.localhost',
				server: 'http://localhost:4500/graphql',
			},
			'mcharraire.fr': {
				espace_prive: 'http://espace-prive.mcharraire.fr',
				local: 'http://local.mcharraire.fr',
				server: 'https://server.admin.mcharraire.fr/graphql',
			},
			localhost: {
				// Ajout de localhost
				espace_prive: 'http://localhost/mcharraire/espace-prive',
				local: 'http://localhost/mcharraire/',
				server: 'http://localhost/mcharraire/graphql',
			},
			'mcharraire.web.adelios.fr': {
				// Ajout de la préprod
				espace_prive: 'http://espace-prive.mcharraire.web.adelios.fr',
				local: 'http://local.mcharraire.web.adelios.fr',
				server: 'https://server.admin.mcharraire.web.adelios.fr/graphql',
			},
		}[window.location.hostname];

		let getargs = window.location.search.substring(1);
		if (getargs.length > 0) {
			let logout = this._parse_query_string(getargs).logout;
			if (logout) {
				this.logout();
			}
		}

		ajax.setGraphqlURL(this._links.server);
		this._token = $$.getCookie('token') || null;
	}
	_parse_query_string(query) {
		var vars = query.split('&');
		var query_string = {};
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			var key = decodeURIComponent(pair[0]);
			var value = decodeURIComponent(pair[1]);
			// If first entry with this name
			if (typeof query_string[key] === 'undefined') {
				query_string[key] = decodeURIComponent(value);
				// If second entry with this name
			} else if (typeof query_string[key] === 'string') {
				var arr = [query_string[key], decodeURIComponent(value)];
				query_string[key] = arr;
				// If third or later entry with this name
			} else {
				query_string[key].push(decodeURIComponent(value));
			}
		}
		return query_string;
	}

	logout() {
		$$.deleteCookie('token');
		window.location.reload();
	}

	get _email() {
		return this._emailInput.value;
	}

	get _password() {
		return this._passwordInput.value;
	}

	_init() {
		this._el.get('.wrapper').style.height =
			this._el.get('.step1').getBoundingClientRect().height + 'px';

		this._el.getAll('input').forEach((input) => {
			input.on('blur', (e) => {
				if (e.target.value != '') {
					e.target.classList.add('filled');
				} else {
					e.target.classList.remove('filled');
				}
			});
		});

		this._el.get('#loginBtn').on('click', (e) => this.login());

		this._emailInput.on('keyup', (e) => {
			if (e.keyCode === 13) {
				this.login();
			}
		});
		this._passwordInput.on('keyup', (e) => {
			if (e.keyCode === 13) {
				this.login();
			}
		});
	}

	open() {
		clearTimeout(this._closingTimeout);
		this._opened = true;
		this._el.classList.add('visible');
	}

	close() {
		this._opened = false;
		this._el.className = '';
		this._closingTimeout = setTimeout((_) => this.prev(), 300);
		this._hideError();
	}

	toggle() {
		if (this._opened) {
			this.close();
		} else {
			this.open();
		}
	}

	next(link) {
		this._link = this._links[link];
		if (this._token) {
			this._checkToken().then((e) => {
				if (e) {
					this._redirectToLink();
				} else {
					this._next();
				}
			});
		} else {
			this._next();
		}
	}
	_next() {
		this._el.get('.step1').classList.add('hidden');
		this._el.get('.wrapper').style.height =
			this._el.get('.step2').getBoundingClientRect().height + 'px';
		this._el.get('.step2').classList.remove('hidden');
	}

	prev() {
		this._el.get('.step2').classList.add('hidden');
		this._el.get('.wrapper').style.height =
			this._el.get('.step1').getBoundingClientRect().height + 'px';
		this._el.get('.step1').classList.remove('hidden');
	}

	error(msg) {
		this._errorDiv.innerText = msg;
		this._showError();
		// setTimeout(_ => this._hideError(), 2500);
		return msg;
	}

	_showError() {
		this._errorDiv.classList.add('show');
	}

	_hideError() {
		this._errorDiv.classList.remove('show');
	}

	_checkToken() {
		return new Promise((resolve, reject) => {
			ajax.setAuthToken(this._token);
			ajax
				.graphql({
					query: `query {
					checkToken
				}`,
				})
				.then((e) => {
					if (e.data.checkToken) {
						resolve(true);
					} else {
						resolve(false);
					}
				}, reject);
		});
	}

	login() {
		return new Promise((resolve, reject) => {
			if (this._email !== '' && this._password !== '') {
				ajax
					.graphql({
						variables: {
							email: this._email,
							password: this._password,
						},
						query: this._loginQuery,
					})
					.then(({ data: { login } }) => {
						if (login) {
							this._token = login.token;
							this._saveToken();
							this._redirectToLink();
							this._hideError();
							return resolve(true);
						} else {
							return reject(
								this.error(
									'Connexion échouée, veuillez vérifier vos informations de connexion.'
								)
							);
						}
					});
			} else {
				return reject(this.error('Un des champs est vide ou invalide.'));
			}
		});
	}

	_saveToken() {
		$$.setCookie('token', this._token, 7);
	}

	_redirectToLink() {
		document.location.href = `${this._link}/?token=${this._token}`;
	}
}

let ep = new espace_prive();

/*--------------------------------------------------------------
	Contact
--------------------------------------------------------------*/
toggle.contact = function () {
	$$.id('popupContact').classList.toggle('visible');
};
close.contact = function () {
	$$.id('popupContact').className = '';
};

/*--------------------------------------------------------------
	Actus
--------------------------------------------------------------*/
toggle.ah = function () {
	$$.id('actusH').classList.toggle('open');
	$$.tag('footer').classList.toggle('open');
};
