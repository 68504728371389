/*--------------------------------------------------------------
VALEURS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- iPad
- Init
- Destroy
--------------------------------------------------------------*/

/*--------------------------------------------------------------
	iPad
--------------------------------------------------------------*/
var ipad = {
	init: function () {
		this.img = $$.first('.ipad div');
		if (isTouch || window.innerWidth < 1025) return;
		window.addEventListener('scroll', ipad.pos);
		window.addEventListener('resize', ipad.pos);
		this.autoplay();
	},
	destroy: function () {
		if (isTouch) return;
		window.removeEventListener('scroll', ipad.pos);
		window.removeEventListener('resize', ipad.pos);
	},
	pos: function () {
		var offset = ipad.img.getBoundingClientRect().top;
		if (offset - window.innerHeight < offset * 0.3)
			ipad.img.style.transform = ipad.img.style.webkitTransform =
				'translateY(-' + offset * 0.3 + 'px)';
	},
	autoplay: function () {
		$$.repeat(2400, ipad.pos);
	},
};

/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.valeurs = function () {
	ipad.init();
	parallaxes.init();
};

/*--------------------------------------------------------------
	Destroy
--------------------------------------------------------------*/
destroy.valeurs = function () {
	parallaxes.destroy();
	ipad.destroy();
};
