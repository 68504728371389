/*--------------------------------------------------------------
ENGAGEMENTS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Init
- Destroy
--------------------------------------------------------------*/

/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.engagements = function () {
	parallaxes.init();
};

/*--------------------------------------------------------------
	Destroy
--------------------------------------------------------------*/
destroy.engagements = function () {
	parallaxes.destroy();
};
